import { Button, Drawer, Loading } from "@global";
import { Container, Form, GridContainer } from "@util/standard";
import React, { useEffect, useState } from "react";

import AccountTemplate from "@shared/account/accountTemplate";
import { Order } from "@state/types";
import OrderObject from "@shared/account/orderComponents";
import { PackageTier } from "@util/types";
import firebase from "gatsby-plugin-firebase";
import { getDocsCollection } from "@util/firebase";
import { isBrowser } from "@util/helper";
import { useAddToCart } from "@util/cartHooks";
import { useStore } from "@state/store";

const Orders = () => {
  const { user } = useStore();
  const [orders, setOrders] = useState<Order[] | null>(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    if (!isBrowser()) return;
    const firestore = firebase.firestore();

    console.log("user", user);

    const getOrders = async () => {
      if (user.isAdmin) {
        const adminOrders = await getDocsCollection(firestore.collection("orders"));
        const completedOrders = adminOrders?.filter(order => order.status === "complete");
        if (completedOrders) setOrders(completedOrders);
        return;
      } else {
        console.log("here");

        const orders = await getDocsCollection(
          firestore.collection("orders").where("uid", "==", user.id),
        );
        console.log(orders);

        const completedOrders = orders?.filter(order => order.status === "complete");
        if (completedOrders) setOrders(completedOrders);
      }
    };

    getOrders();
  }, [user]);

  const handleProOrderClick = () => {
    useAddToCart({
      type: "pro",
      tier: "basic",
      price: 0,
    });
  };

  return (
    <AccountTemplate handle="pro-orders">
      <Container margin="0 0 35px" tabletMargin="0 0 20px" flexFlow="column nowrap">
        <h2 className="h4">Pro Orders</h2>
      </Container>
      {orders ? (
        <GridContainer width="100%" gridTemplateColumns="1fr" gap="10px">
          {orders.length > 0 ? (
            orders.map(order => (
              <OrderObject key={order.uuid} order={order} setOrders={setOrders} />
            ))
          ) : (
            <>No orders found</>
          )}
        </GridContainer>
      ) : (
        <Container flexFlow="column nowrap" width="100%" margin="35px 0" alignItems="center">
          <Loading baseColor="lightBlue" color="main" />
          <p>Retreiving order, please hang on</p>
        </Container>
      )}
      <Container margin="50px auto">
        <Button theme="baseOutline" onClick={handleProOrderClick} linkText="Create New Pro Order" />
      </Container>
      <Drawer visible={visible} onClose={() => setVisible(false)}>
        <Container>
          <h2 className="h4">Create New Pro Order</h2>
        </Container>
      </Drawer>
    </AccountTemplate>
  );
};

export default Orders;
